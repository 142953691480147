import { SeatStatusEnum } from 'src/graphql/generated/types';

export const SeatColorSheme = Object.freeze({
  [SeatStatusEnum.Available]: '#ecf0f1',
  [SeatStatusEnum.Reserved]: '#95a5a6',
  [SeatStatusEnum.Occupied]: '#27ae60',
  [SeatStatusEnum.Blocked]: '#e74c3c',
});

export const statusActionsMap = (currentStatus: SeatStatusEnum) => {
  switch (currentStatus) {
    case SeatStatusEnum.Blocked:
      return [SeatStatusEnum.Available, SeatStatusEnum.Reserved, SeatStatusEnum.Occupied];
    case SeatStatusEnum.Available:
      return [SeatStatusEnum.Blocked, SeatStatusEnum.Reserved, SeatStatusEnum.Occupied];
    case SeatStatusEnum.Reserved:
      return [SeatStatusEnum.Available, SeatStatusEnum.Blocked, SeatStatusEnum.Occupied];
    case SeatStatusEnum.Occupied:
    default:
      return [SeatStatusEnum.Available, SeatStatusEnum.Blocked, SeatStatusEnum.Reserved];
  }
};
